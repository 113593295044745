<template>
    <div>
        <div class="main-title">上传文件</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">说明</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">支持批量上传（单次最多10个），支持PDF/PPT/WORD格式</li>
                        <li data-v-69874ad0="">文件大小不超过40M，文件名请勿包含特殊字符</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="detail-info">
            <el-form ref="form" :model="form" label-width="120px" size="mini" >

                <el-form-item label="选择文件：" label-position="left" >
                    <upload-file @onChange="onChange" @onSuccess="onSuccess"></upload-file>
                </el-form-item>

                <el-form-item label="已上传文件：" label-position="left" >
                    <el-table
                        style="width: 700px"
                        size="mini"
                        highlight-current-row
                        border
                        :data="tableData">
                        <el-table-column
                            prop="name"
                            label="文件名">
                        </el-table-column>
                        <el-table-column
                            prop="type"
                            label="文件类型"
                            width="120">
                        </el-table-column>
                        <el-table-column
                            prop="size"
                            label="文件大小"
                            width="120">
                            <template slot-scope="scope">
                                <span class="state-up">
                                     {{parseFloat((scope.row.size/1024)/1024).toFixed(2) + 'M'}}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            width="120"
                            label="上传状态">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status == 'fail'" ><el-tag type="danger" size="mini">上传失败</el-tag></span>
                                <span v-if="scope.row.status == 'success'"><el-tag type="success" size="mini">上传成功</el-tag></span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>



        </div>

    </div>
</template>

<script>
    import UploadFile from "../../../components/uploadFile";
    import {mapActions} from "vuex";
    export default {
        name: "add",
        components: {UploadFile},
        data(){
            return {
                form: {

                },
                uploadSuccessFiles:[],
                fileList: [],
                tableData: []
            }
        },
        methods: {
            ...mapActions('file',['addFile']),
            onChange(data){
                this.tableData = data
            },
            onSuccess(data){
                var _this = this
                this.uploadSuccessFiles.push(data)
                this.addFile(data).then(res => {
                    if(res.ret == 0 && res.data.name != undefined){
                        this.$notify({
                            title: '上传成功',
                            message: res.data.name ,
                            type: 'success'
                        });
                    }else{
                        this.$notify({
                            title: '上传失败',
                            message: res.data.name ,
                            type: 'error'
                        });
                    }
                })
            }

        }
    }
</script>

<style >
.el-upload-dragger{
    width: 700px;
    height: 120px;
    border: 1px solid #ebebeb;
    background-color: #fafafa;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.el-upload-dragger .el-icon-upload {
    font-size: 40px;
    color: #C0C4CC;
    margin:10px auto;
    line-height: 40px;
}
.el-table th{
    background: #fafafa;
}
.el-table--mini th{
    padding:0
}
    .upload-demo{
        width: 700px;
    }
</style>
